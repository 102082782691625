import { useStorage } from '@vueuse/core';

const currency = useStorage('currency', 'UAH');

export function useCurrency(locale: string) {
  function formatCurrency(price: number, priceCurrency: undefined = undefined) {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: priceCurrency ?? currency.value,
      minimumFractionDigits: 0,
    });
    return formatter.format(price);
  }

  return {
    currency,
    formatCurrency,
  }
}
